<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>IntelectuGal</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col align-self-center>
            <ion-button
              expand="block"
              router-link="/rexistrarse"
              color="primary"
              >Rexistrarse</ion-button
            >
            <span class="divider line one-line">ou</span>

            <span class="already">Xa tes unha conta?</span>

            <ion-button
              expand="block"
              router-link="/identificarse"
              color="danger"
              >Identificarse</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";

export default {
  name: "Auth",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/materiais');
    }
  },
};
</script>

